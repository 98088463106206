import {i18n} from "../../../../Utils/i18n/i18n";
import {get, map} from 'lodash'
import React, {useContext, useEffect, useState} from "react";
import {DataGridWrapper} from "../../../../Utils/DataGridWrapper/DataGridWrapper";
import {getAdminUsersAPI} from "../../../../Services/api";
import {useUserAdminOrgs} from "../../../Hooks/UseUserAdminOrgs";
import LoadingSpinner from "../../../LoadingSpinner/LoadingSpinner";
import './AdminUsersTable.scss'
import {UserReactContext} from "../../../../contexts/UserContext";
import {AddEditAdminUserModal} from "../AddEditAdminUserModal/AddEditAdminUserModal";
import {EditAdminUserCell} from "../EditAdminUserCell/EditAdminUserCell";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import {buttonColors} from "../../../Pickers/InputTheme";
import {GridReactContext} from "../../../../Utils/DataGridWrapper/GridContext";

const getUsersColumns = ({isCompanyAdmin}) => {

    let columns = [
        {
            field: 'firstName',
            headerName: i18n.translate("adminUsersTable.columns.firstName"),
            minWidth: 150,
            align: 'right',
            sortable: false,
            renderCell: (params) => <div style={{width: '100%'}}>{params.value}</div>,
        },
        {
            field: 'lastName',
            headerName: i18n.translate("adminUsersTable.columns.lastName"),
            minWidth: 150,
            align: 'right',
            sortable: false,
            renderCell: (params) => <div style={{width: '100%'}}>{params.value}</div>,
        },
        {
            field: 'managerOfOrganizations',
            headerName: i18n.translate("adminUsersTable.columns.managerOfOrganizations"),
            minWidth: 400,
            align: 'right',
            sortable: false,
            renderCell: (params) => <div style={{width: '100%'}} title={params.value}>{params.value}</div>,
        },
        {
            field: 'id',
            headerName: i18n.translate("allUsersTable.columns.edit"),
            minWidth: 180,
            align: 'right',
            sortable: false,
            renderCell: (params) => <EditAdminUserCell user={params.row} isCompanyAdmin={isCompanyAdmin}/>,
        }
    ];
    if (isCompanyAdmin) {
        columns.push({
            field: 'isCompanyAdmin',
            headerName: i18n.translate("adminUsersTable.columns.isCompanyAdmin"),
            minWidth: 150,
            align: 'right',
            sortable: false,
            renderCell: (params) => <div
                style={{width: '100%'}}>{params.value ? i18n.translate("adminUsersTable.columns.isCompanyAdminYes") : i18n.translate("adminUsersTable.columns.isCompanyAdminNo")}</div>,
        })
    }
    return columns
}

const tableFilters = {
    showFilters: {
        organizations: true,
        search: true,
    },
    filterValues: {},
    filterData: {}
}

const convertData = (users) => {
    return map(users, (user) => {
        return {
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            managerOfOrganizations: map(user.organizations, org => {
                return org.orgName
            }).join(', '),
            isCompanyAdmin: user.isCompanyAdmin
        }
    })
}

const paginationModel = {
    page: 0,
    pageSize: 20,
}

export const AdminUsersTable = () => {
    const {adminOrganizationOptions, adminOrganizations, isOrgsLoading} = useUserAdminOrgs({withAllOption: true})
    const [localTableFilters, setLocalTableFilters] = useState()
    const [editAdminUserModalOpen, setEditAdminUserModalOpen] = useState(false)
    const userContext = useContext(UserReactContext)
    const gridContext = React.useContext(GridReactContext)
    const isCompanyAdmin = userContext.isManagingCompany()
    useEffect(() => {
        if (isOrgsLoading) {
            return
        }
        setLocalTableFilters({
            ...tableFilters,
            filterData: {
                ...tableFilters.filterData,
                organizations: adminOrganizationOptions
            },
            filterValues: {
                ...tableFilters.filterValues,
                organizationId: get(adminOrganizationOptions, '[0].value')
            }
        })

    }, [isOrgsLoading]);

    const closeCreateEditUserModal = () => {
        setEditAdminUserModalOpen(false)
        gridContext.triggerTableRefresh()
    }
    const addAdminUser = () => {
        setEditAdminUserModalOpen(true)
    }
    const tableButtons = [
        {
            icon: <PersonAddIcon/>,
            color: buttonColors.GREY,
            text: i18n.translate('adminUsersTable.addAdminUser'),
            onClick: ({paginationModel, filterModel, sortModel}) => {
                addAdminUser()
            }
        }
    ]
    const getColumns = async ({paginationModel, filterModel}) => {
        return getUsersColumns({isCompanyAdmin})
    }
    const apiToGetData = async ({paginationModel, filterModel}) => {
        return getAdminUsersAPI({
            paginationModel,
            filterModel
        })
    }

    if (!localTableFilters) {
        return <LoadingSpinner/>
    }
    return <>
        <DataGridWrapper
            filterModel={localTableFilters}
            disableColumnFilter
            paginationModel={paginationModel}
            getColumns={getColumns}
            apiToGetData={apiToGetData}
            dataConverter={convertData}
            buttons={tableButtons}
        />
        {editAdminUserModalOpen ? <AddEditAdminUserModal editAdminUserModalOpen={editAdminUserModalOpen} closeEditAdminUserModal={closeCreateEditUserModal} isCompanyAdmin={isCompanyAdmin}/> : ''}
    </>
}