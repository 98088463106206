import {i18n} from "../../../Utils/i18n/i18n";
import {get, map} from 'lodash'
import React, {useState} from "react";
import {DataGridWrapper} from "../../../Utils/DataGridWrapper/DataGridWrapper";
import {
    getAllSignDocsOrganizationUsersCSVApi,
    getOrganizationSignDocsApi,
    getOrganizationUsersForSignDocsApi,
    getSignDocsOrganizationUsersCSVApi
} from "../../../Services/api";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import {UserRowSignDocsMenu} from "../UserRowSignDocsMenu/UserRowSignDocsMenu";
import {Navigate} from "react-router-dom";

const getUsersColumns = ({organizationSignDocs, organizationId}) => {
    const signDocsColumns = map(organizationSignDocs, (orgSignDoc) => {
        const signDoc = orgSignDoc
        return {
            field: `signDoc-${signDoc.id}`,
            headerName: signDoc.docName,
            minWidth: 200,
            align: 'right',
            sortable: true,
            renderCell: (params) => {
                const signed = get(params, 'value.signed')
                const viewed = get(params, 'value.viewed')
                const type = get(signDoc, 'type')
                const progressPercent = get(params, 'value.progressPercent')
                const finishByDate = get(params, `value.finishByDate`)
                const completedByUserName = get(params, `value.completedByUserName`)
                const completedOnDate = get(params, `value.completedOnDate`)
                const isValid = get(params, `value.isValid`)
                const userId = get(params, 'row.id')
                const userName = get(params, 'row.firstName') + ' ' + get(params, 'row.lastName')
                return <UserRowSignDocsMenu signDoc={signDoc} signed={signed} viewed={viewed} userId={userId}
                                            progressPercent={progressPercent}
                                            type={type}
                                            completedByUserName={completedByUserName}
                                          userName={userName} finishByDate={finishByDate} organizationId={organizationId} completedOnDate={completedOnDate} isSignDocValid={isValid}/>
            },
        }
    })
    return [
        {
            field: 'firstName',
            headerName: i18n.translate("superAdmin.allUsersTable.columns.firstName"),
            minWidth: 100,
            align: 'right',
            sortable: false,
            renderCell: (params) => <div style={{width: '100%'}}>{params.value}</div>,
        },
        {
            field: 'lastName',
            headerName: i18n.translate("superAdmin.allUsersTable.columns.lastName"),
            minWidth: 100,
            align: 'right',
            sortable: false,
            renderCell: (params) => <div style={{width: '100%'}} title={params.value}>{params.value}</div>,
        },
        {
            field: 'identifier',
            headerName: i18n.translate("superAdmin.allUsersTable.columns.identifier"),
            minWidth: 120,
            align: 'right',
            sortable: false,
            renderCell: (params) => <div style={{width: '100%'}} title={params.value}>{params.value}</div>,
        },
        // {
        //     field: 'phone',
        //     headerName: i18n.translate("superAdmin.allUsersTable.columns.phone"),
        //     minWidth: 100,
        //     align: 'right',
        //     sortable: false,
        //     renderCell: (params) => <div style={{width: '100%'}} title={params.value}>{params.value}</div>,
        // },
        // {
        //     field: 'email',
        //     headerName: i18n.translate("superAdmin.allUsersTable.columns.email"),
        //     minWidth: 180,
        //     align: 'right',
        //     sortable: false,
        //     renderCell: (params) => <div style={{width: '100%'}} title={params.value}>{params.value}</div>,
        // },
        // {
        //     field: 'id',
        //     headerName: i18n.translate("allUsersTable.columns.edit"),
        //     minWidth: 30,
        //     align: 'right',
        //     sortable: false,
        //     renderCell: (params) => <EditUserCell user={params.row}/>,
        // },
        // {
        //     headerName: i18n.translate("allUsersTable.columns.delete"),
        //     minWidth: 30,
        //     align: 'right',
        //     sortable: false,
        //     renderCell: (params) => <RemoveUserCell user={params.row}/>,
        // },
        ...signDocsColumns
    ];
}

const tableFilters = {
    showFilters: {
        search: true,
        organizations: true
    },
    filterValues: {},
    filterData: {}
}
const paginationModel = {
    page: 0,
    pageSize: 20,
}

const convertData = (users) => {
    return map(users, (user) => {
        let retObj = {
            ...user
        }
        let userSignDocs = get(user, 'userSignDocs')
        for (let i = 0; i < userSignDocs.length; i++) {
            retObj[`signDoc-${userSignDocs[i].SignDocId}`] = userSignDocs[i]
        }
        return retObj
    })
}


export const OrganizationUsersSignDocsTable = ({organizationsOptions, types}) => {
    const [localTableFilters, setLocalTableFilters] = useState({
        ...tableFilters,
        filterData: {
            ...tableFilters.filterData,
            organizations: organizationsOptions
        },
        filterValues: {
            ...tableFilters.filterValues,
            organizationId: get(organizationsOptions, '[0].value'),
            types
        }
    })
    const [navigateToReports, setNavigateToReports] = useState(false)
    const getColumns = async ({paginationModel, filterModel}) => {
        const organizationId = get(filterModel, 'filterValues.organizationId')
        let organizationSignDocs = await getOrganizationSignDocsApi({organizationId, types, doNotForceCompletion:false})
        return getUsersColumns({organizationSignDocs, organizationId})
    }
    const apiToGetData = async ({paginationModel, filterModel, sortModel}) => {
        /**
         * This logic is to handle sorting by signDocs column
         * The sorting is complex because we need to sort by progress column
         * and the query to the server does not know how to handle it, so the signDoc arrives as filter and being sort by,
         * and all other signDocs are joined to the user in a subsequent query to the DB
         * if the amount of signDocs is large in one page, this can lead to a performance issue
        **/
        //if sort model conatins signDocs column, we need add it as filter
        //clone sort model to avoid changing the original object
        let newSortModel = JSON.parse(JSON.stringify(sortModel))
        let newFilterModel = JSON.parse(JSON.stringify(filterModel))
        if (newSortModel && newSortModel.length > 0 && newSortModel[0].field.includes('signDoc-')) {
            const sortModelField = get(newSortModel, '[0].field')
            //find "course-" index
            const signDocIndex = sortModelField.indexOf('signDoc-') + 8
            //remove "course-" from field name
            newFilterModel.filterValues.signDocId = sortModelField.substring(signDocIndex)
            // newFilterModel.filterValues.types = types
            //set sort model to progress
            newSortModel = [{
                field: 'progress',
                sort: sortModel[0].sort
            }]
        }
        return getOrganizationUsersForSignDocsApi({
            paginationModel,
            filterModel: newFilterModel,
            sortModel: newSortModel
        })
    }

    const buttons = [
        //download csv button
        // {
        //     type: 'IconButton',
        //     icon: <CloudDownloadIcon/>,
        //     label: i18n.translate('superAdmin.allUsersTable.buttons.downloadCsvForOrganization'),
        //     onClick: async ({paginationModel, filterModel, sortModel}) => {
        //         await getOrganizationUsersCSVApi({paginationModel, filterModel, sortModel})
        //         alert(i18n.translate('superAdmin.allUsersTable.buttons.downloadCsvForOrganizationSuccess'))
        //     }
        // },
        // {
        //     type: 'IconButton',
        //     icon: <DynamicFeedIcon/>,
        //     label: i18n.translate('superAdmin.allUsersTable.buttons.downloadCsvForAllOrganizations'),
        //     onClick: ({paginationModel, filterModel, sortModel}) => {
        //         getOrganizationUsersCSVApi({paginationModel, filterModel, sortModel})
        //     }
        // },
        {
            type: 'MultipleOptionsButton',
            text: i18n.translate('superAdmin.allUsersTable.buttons.downloadCsvForOrganization'),
            icon: <CloudDownloadIcon/>,
            options: [
                {
                    text: i18n.translate('superAdmin.allUsersTable.buttons.downloadCsvForOrganization'),
                    action: async ({paginationModel, filterModel, sortModel}) => {
                        //remove courseId from filterModel
                        let filterModelCopy = JSON.parse(JSON.stringify(filterModel))

                        await getSignDocsOrganizationUsersCSVApi({paginationModel, filterModel: filterModelCopy, sortModel})
                        setNavigateToReports(true)
                    }
                },
                {
                    text: i18n.translate('superAdmin.allUsersTable.buttons.downloadCsvForAllOrganizations'),
                    action: async ({paginationModel, filterModel, sortModel}) => {
                        //remove courseId from filterModel
                        let filterModelCopy = JSON.parse(JSON.stringify(filterModel))
                        await getAllSignDocsOrganizationUsersCSVApi({paginationModel, filterModel: filterModelCopy, sortModel})
                        setNavigateToReports(true)
                    }
                }
            ]
        }
    ]
    if(navigateToReports){
        return <Navigate to={{pathname: `/reports/generatedReports`}} replace={true}/>
    }
    return <DataGridWrapper
            filterModel={localTableFilters}
            disableColumnFilter
            paginationModel={paginationModel}
            getColumns={getColumns}
            apiToGetData={apiToGetData}
            dataConverter={convertData}
            buttons={buttons}
        />
}