import React, {useState, useEffect} from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {i18n} from "../../../Utils/i18n/i18n";
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;
export const MultiSelectAutocomplete = ({
                                            fetchOptions,
                                            onSelectionChange,
                                            isSingleSelect = false,
                                            initialSelectedValues,
                                            label,
                                            disabled,
                                            enableSelectAll,
                                            selectAllText
                                        }) => {
    const [query, setQuery] = useState("");
    const [debouncedQuery, setDebouncedQuery] = useState("");
    const [options, setOptions] = useState([]);
    const [selectedItems, setSelectedItems] = useState(() => {
        if (initialSelectedValues) {
            return initialSelectedValues;
        }
        return isSingleSelect ? null : [];
    });

    // Debounce the user input
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedQuery(query);
        }, 300); // Delay of 300ms

        return () => {
            clearTimeout(handler);
        };
    }, [query]);

    const fetchData = async () => {
        try {
            const data = await fetchOptions({query: debouncedQuery});
            if (enableSelectAll && !isSingleSelect) {
                //check if select all is already present
                const selectAll = data.find(option => option.value === 'selectAll');
                if (!selectAll){
                    data.unshift({value: 'selectAll', text: selectAllText || i18n.translate('autoComplete.selectAll')});
                }
            }
            setOptions(data || []);
        } catch (error) {
            console.error("Error fetching options:", error);
        }
    };
    useEffect(() => {
        fetchData()
    }, [])

    // Fetch options based on the debounced query
    useEffect(() => {
        if (debouncedQuery.trim() === "") {
            setOptions([]);
            return;
        }
        fetchData();
    }, [debouncedQuery]);

    const handleSelectionChange = (event, value) => {
        if (Array.isArray(value) && value.some(item => item.value === 'selectAll')) {
            if (selectedItems.length === options.length - 1) {
                setSelectedItems([]);
                onSelectionChange([]);
            } else {
                const allOptions = options.filter(option => option.value !== 'selectAll');
                setSelectedItems(allOptions);
                onSelectionChange(allOptions);
            }
        } else {
            setSelectedItems(value);
            onSelectionChange(value);
        }
    };

    return (
        <Autocomplete
            limitTags={2} // Limit the number of tags displayed
            multiple={!isSingleSelect} // Enable multiple selections based on the prop
            disabled={disabled}
            options={options} // Options from the API
            value={selectedItems} // Controlled selected values
            onChange={handleSelectionChange} // Handle changes in selection
            onInputChange={(event, newInputValue) => setQuery(newInputValue)} // Update query for fetching options
            filterOptions={(x) => x} // Disable MUI's default filtering (API handles filtering)
            renderInput={(params) => (
                <TextField {...params} label={label || i18n.translate('autoComplete.search')} variant="outlined"
                           fullWidth/>
            )}
            renderOption={(props, option, {selected}) => {
                const {key, ...optionProps} = props;
                return (
                    <li key={key} {...optionProps}>
                        {!isSingleSelect ? <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{marginRight: 8}}
                            checked={selected}
                        /> : ''}
                        {option.text}
                    </li>
                );
            }}
            disableCloseOnSelect={!isSingleSelect} // Keep dropdown open for multi-select
            getOptionLabel={(option) => option.text} // Display option text
        />
    );
};

