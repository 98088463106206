import './AdminUsers.scss'
import React, {useContext} from "react";
import {UserReactContext} from "../../../contexts/UserContext";
import {GridReactContextProvider} from "../../../Utils/DataGridWrapper/GridContext";
import {CompanyReactContext} from "../../../contexts/CompanyContext";
import {AdminUsersTable} from "./AdminUsersTable/AdminUsersTable";


export const AdminUsers = () => {
    return <div className="AdminUsers">
        <GridReactContextProvider>
            <AdminUsersTable/>
        </GridReactContextProvider>

    </div>
}