import {i18n} from "./i18n/i18n";
import {SignDocTypes} from "../Components/SignDoc/SignDocLib";

export const signDocToTextProgressValueConverter = ({progressPercent, isSignDocValid, type, viewed, signed}) => {
    let progress, isCompleted;
    if (type === SignDocTypes.REGULAR_COURSE) {
        if (isSignDocValid && (progressPercent === 0 || !progressPercent)) {
            progress = i18n.translate('courseProgressMgmnt.notStarted')
            isCompleted = false
        } else if (progressPercent > 0 && progressPercent < 100) {
            progress = i18n.translate('courseProgressMgmnt.inProgress')
            isCompleted = false
        } else if (progressPercent === 100) {
            progress = i18n.translate('courseProgressMgmnt.finished')
            isCompleted = true
        }
    } else {
        if (isSignDocValid && !viewed && !signed) {
            progress = i18n.translate('signDocProgressMgmnt.notStarted')
            isCompleted = false
        } else if (viewed && !signed) {
            progress = i18n.translate('signDocProgressMgmnt.inProgress')
            isCompleted = false
        } else if (signed) {
            progress = i18n.translate('signDocProgressMgmnt.finished')
            isCompleted = true
        }
    }
    return {
        progress,
        isCompleted
    }

}
export const signDocToClassProgressValueConverter = ({signed, viewed, progressPercent, type}) => {
    if (type === SignDocTypes.REGULAR_COURSE) {
        if (progressPercent === 0 || !progressPercent) {
            return "notStarted"
        } else if (progressPercent > 0 && progressPercent < 100) {
            return "inProgress"
        } else if (progressPercent === 100) {
            return "completed"
        }
    } else {
        if (signed) {
            return "completed"
        } else if (viewed) {
            return "inProgress"
        } else {
            return "notStarted"
        }
    }
}


export const getSignDocDateText = ({
                                       finishByDate,
                                       completedOnDate,
                                       viewed,
                                       signed,
                                       type,
                                       progressPercent,
                                       completedByUserName
                                   }) => {
    const dateOptions = {day: '2-digit', month: '2-digit', year: 'numeric'};
    let signDocDate = new Date(completedOnDate).toLocaleDateString('en-GB', dateOptions)
    let signDocDateText;
    if (type === SignDocTypes.REGULAR_COURSE) {
        if (progressPercent === 100) {
            if (!completedOnDate) {
                return undefined
            } else {
                if (completedByUserName) {
                    signDocDateText = i18n.translate('courseProgressMgmnt.by') + completedByUserName
                } else {
                    signDocDateText = i18n.translate('courseProgressMgmnt.completedOnCourse') + signDocDate
                }
            }
        } else if (progressPercent < 100) {
            if (!finishByDate) {
                return undefined
            } else {
                if (completedByUserName) {
                    signDocDateText = i18n.translate('courseProgressMgmnt.by') + completedByUserName
                } else {
                    signDocDateText = i18n.translate('courseProgressMgmnt.finishByCourse') + signDocDate
                }
            }

        } else {
            return undefined
        }
    } else {
        if (signed) {
            if (!completedOnDate) {
                return undefined
            } else {
                if (completedByUserName) {
                    signDocDateText = i18n.translate('courseProgressMgmnt.by') + completedByUserName
                }
                else{
                    signDocDateText = i18n.translate('courseProgressMgmnt.completedOn') + signDocDate
                }
            }
        } else if (viewed) {
            if (!finishByDate) {
                return undefined
            } else {
                if (completedByUserName) {
                    signDocDateText = i18n.translate('courseProgressMgmnt.by') + completedByUserName
                }
                else{
                    signDocDateText = i18n.translate('courseProgressMgmnt.finishBy') + signDocDate
                }
            }
        } else {
            return undefined
        }
    }
    return signDocDateText

}
