import rest from '../Utils/rest'


export const userLogin = ({identifier, password}) => {
    return rest.jsonPostUrl(`/api/user/login`, {identifier, password})
}
export const loginWithTokenAPI = ({token}) => {
    return rest.jsonPostUrl(`/api/user/tokenLogin`, {loginToken: token})

}
export const validate2fa = ({code}) => {
    return rest.jsonPostUrl(`/api/user/validate2fa`, {code})
}
export const requestAnother2fa = () => {
    return rest.jsonPostUrl(`/api/user/requestAnother2faCode`)
}
export const changePasswordApi = ({oldPassword, newPassword}) => {
    return rest.jsonPostUrl(`/api/changePassword`, {oldPassword, newPassword})
}
export const userLogout = () => {
    return rest.jsonPostUrl(`/api/logout`)
}



export function getUserOrganizationsWithNotesAndCourses() {
    return rest.getUrl(`/api/organizations/allWithNotesAndCourses`)
}
export function getUserOrganizationsPlain() {
    return rest.getUrl(`/api/organizations/allPlain`)
}


export function fetchUserCompanyLogo() {
    return rest.getUrl(`/api/companies/getLogo`)
}

export function getOrganizationUsersApi({paginationModel, filterModel, sortModel}) {
    return rest.jsonPostUrl(`/api/organizations/users`, {paginationModel, filterModel, sortModel})
}

export function getOrganizationUsersForSignDocsApi({paginationModel, filterModel, sortModel}) {
    return rest.jsonPostUrl(`/api/admin/signDocs/organizations/users/all`, {paginationModel, filterModel, sortModel})
}


export function getSignDocsOrganizationUsersCSVApi({paginationModel, filterModel, sortModel}) {
    return rest.jsonPostUrl(`/api/admin/signDocs/organizations/users/csv`, {paginationModel, filterModel, sortModel})
}

export function getAllSignDocsOrganizationUsersCSVApi({paginationModel, filterModel, sortModel}) {
    return rest.jsonPostUrl(`/api/admin/signDocs/organizations/users/csvAll`, {paginationModel, filterModel, sortModel})
}


export function getAllNotesForCompanyAPI() {
    return rest.jsonPostUrl(`/api/notes/allForCompany`,)
}

export function createNoteAPI({title}) {
    return rest.jsonPostUrl(`/api/notes/create`, {title})
}

export function createUsersForOrganizations({organizationId, users, sendWelcomeMessage}) {
    return rest.jsonPostUrl(`/api/organizations/${organizationId}/createBulkUsers`, {users, sendWelcomeMessage})
}

export function uploadCompanyLogoApi({files, onProgress}) {
    return rest.uploadFiles(`api/companies/uploadLogo`, files, onProgress)
}

export function updateNote({noteId, title, content, orgs}) {
    return rest.jsonPostUrl(`/api/notes/${noteId}/update`, {title, content, orgs})
}


export function updateOrganizationNameApi({orgName, orgId}) {
    return rest.jsonPostUrl(`/api/organizations/${orgId}/updateName`, {orgName})
}

export function startCourseAPI({signDocId}) {
    return rest.getUrl(`/api/signDocs/${signDocId}/startCourse`)
}

export function markDocAsViewedAPI({signDocId}) {
    return rest.getUrl(`/api/signDocs/${signDocId}/markAsViewed`)
}

export function getSignDocApi({signDocId}) {
    return rest.jsonPostUrl(`/api/signDocs/${signDocId}`)
}

export function getSignDocsStatistics({types, organizationId, signDocId}) {
    return rest.jsonPostUrl(`/api/signdocs/statistics`, {types, organizationId, signDocId})
}


export function getOrganizationSignDocsApi({organizationId, types, doNotForceCompletion}) {
    return rest.jsonPostUrl(`/api/signDocs/organizations/${organizationId}`, {types, doNotForceCompletion})
}

export function getAdminSignDocDataAPI({courseId}) {
    return rest.getUrl(`/api/admin/signDocs/${courseId}/`)
}

export function getCompanyPrefixAPI({organizationId}) {
    return rest.getUrl(`/api/organizations/${organizationId}/companyPrefix`)
}


export function addUserToSignDocAPI({signDocId, userId, finishDate, organizationId}) {
    return rest.jsonPostUrl(`/api/admin/signDocs/${signDocId}/addUser`, {
        userIdToUpdate: userId,
        finishDate,
        organizationId
    })
}

export function removeUserSignDocAPI({signDocId, userId, organizationId}) {
    return rest.jsonPostUrl(`/api/admin/signDocs/${signDocId}/remove`, {userIdToUpdate: userId, organizationId})
}
export const markSignDocUserAsCompletedAPI = ({signDocId, userId, organizationId}) => {
    return rest.jsonPostUrl(`/api/admin/signDocs/${signDocId}/markAsCompleted`, {forUserId: userId, organizationId})

}

export function completeCourseAPI({signDocId}) {
    return rest.jsonPostUrl(`/api/signDocs/setCourseCompleted`, {signDocId})
}

export const getUserAPI = ({userId}) => {
    return rest.getUrl(`/api/admin/users/${userId}`)
}

export const getMyAdminOrganizationsUsersAPI = ({filterModel, sortModel}) => {
    return rest.jsonPostUrl(`/api/admin/organizations/users`, {filterModel, sortModel})

}

export const updateUserAPI = ({userId, firstName, lastName, email, phone}) => {
    return rest.jsonPostUrl(`/api/admin/users/${userId}/update`, {firstName, lastName, email, phone})
}
export const removeUserAPI = ({userId}) => {
    return rest.jsonDeleteUrl(`/api/admin/users/${userId}/remove`)
}

export const sendTaskReminderAPI = ({
                                        sendToUserIds = [],
                                        entityId,
                                        entityType,
                                        organizationId,
                                        skipAlreadyNotifiedUsers,
                                        messagingTypes,
                                        allUsersWithOpenTask
                                    }) => {
    return rest.jsonPostUrl(`/api/admin/notifications/sendTaskReminder`, {
        sendToUserIds,
        skipAlreadyNotifiedUsers,
        messagingTypes,
        organizationId,
        entityId,
        entityType,
        allUsersWithOpenTask
    }, {handleErrorByComponent: true})
}
export const userCountToSendSMSAPI = ({
                                          sendToUserIds = [],
                                          entityId,
                                          entityType,
                                          organizationId,
                                          skipAlreadyNotifiedUsers,
                                          messagingTypes,
                                          allUsersWithOpenTask
                                      }) => {
    return rest.jsonPostUrl(`/api/admin/notifications/getUserCount`, {
        sendToUserIds,
        organizationId,
        skipAlreadyNotifiedUsers,
        entityId,
        entityType,
        messagingTypes,
        allUsersWithOpenTask
    }, {handleErrorByComponent: true})
}

export const getCompanySMSQuotaAPI = ({companyId}) => {
    return rest.getUrl(`/api/admin/companies/${companyId}/getSMSQuota`)
}

export const getCompanyFilesAPI = ({paginationModel, filterModel, sortModel}) => {
    return rest.jsonPostUrl(`/api/companyFiles`, {paginationModel, filterModel, sortModel})
}

export const getCompanyFileDownloadLinkAPI = ({fileId}) => {
    return rest.getUrl(`/api/companyFiles/${fileId}/getDownloadLink`)
}

export const createNotificationAutomationAPI = ({
                                                    description,
                                                    name,
                                                    runHour,
                                                    runDaysBefore,
                                                    types,
                                                    subType,
                                                    organizationId,
                                                    entityId,
                                                    entityType,
                                                    automationId
                                                }) => {
    return rest.jsonPostUrl(`/api/automations/create`, {
        description,
        name,
        runHour,
        runDaysBefore,
        types,
        subType,
        organizationId,
        entityId,
        entityType,
        automationId
    })
}

export const getCompanyFeatureFlagsAPI = () => {
    return rest.getUrl(`/api/featureFlags/getCompanyFeatureFlags`, {handleErrorByComponent: true})
}

export const getCompanyAutomations = ({paginationModel, filterModel, sortModel}) => {
    return rest.jsonPostUrl(`/api/automations/getForCompany`, {paginationModel, filterModel, sortModel})
}
export const getAdminUsersAPI = ({paginationModel, filterModel, sortModel}) => {
    return rest.jsonPostUrl(`/api/admin/adminUsers`, {paginationModel, filterModel, sortModel})
}
export const deleteAutomationAPI = ({automationId}) => {
    return rest.jsonPostUrl(`/api/automations/delete`, {automationId})
}
export const createSignDocAPI = ({docName, docDescription, linkUrl, type, selectedOrganizations, notifyOrganizationUserOnNewTask, notifyMessagingTypes, forceCompletion}) => {
    return rest.jsonPostUrl(`/api/admin/signDocs/create`, {docName, docDescription, linkUrl, type, selectedOrganizations, notifyOrganizationUserOnNewTask, notifyMessagingTypes, forceCompletion})
}
export const updateSignDocAPI = ({signDocId, docName, linkUrl, docDescription, selectedOrganizations, notifyOrganizationUserOnNewTask, notifyMessagingTypes, forceCompletion}) => {
    return rest.jsonPostUrl(`/api/admin/signDocs/${signDocId}/update`, {docName, linkUrl, docDescription, selectedOrganizations, notifyOrganizationUserOnNewTask, notifyMessagingTypes, forceCompletion})
}
export const getAllAdminSignDocsAPI = ({types, showForceCompletion, showNonForceCompletion}) => {
    return rest.jsonPostUrl(`/api/admin/signDocs/all`, {types, showForceCompletion, showNonForceCompletion})
}

export function uploadDocumentFileAPI({files, onProgress, documentId}) {
    return rest.uploadFiles(`api/admin/signDocs/${documentId}/upload`, files, onProgress)
}

export const getUserSignDocsAPI = ({types, showForceCompletion, showNonForceCompletion}) => {
    return rest.jsonPostUrl(`/api/signDocs/all`, {types, showForceCompletion, showNonForceCompletion})
}

export const markSignDocAsCompleted = ({signDocId, signatureBase64}) => {
    return rest.jsonPostUrl(`/api/signDocs/markAsRead`, {signDocId, signatureBase64})
}

export const deleteSignDocAPI = ({signDocId}) => {
    return rest.jsonPostUrl(`/api/admin/signDocs/${signDocId}/delete`)
}
export const deleteNoteAPI = ({noteId}) => {
    return rest.jsonPostUrl(`/api/admin/notes/${noteId}/delete`)
}

export const getAdminOrganizationsForUserAPI = ({userId}) => {
    return rest.getUrl(`/api/admin/adminOrganizationsForUser/${userId}`)
}
export const saveUserAsAdminOfOrganizationsAPI = ({userId, organizationIds, companyAdmin = true}) => {
    return rest.jsonPostUrl(`/api/admin/users/${userId}/saveAsAdmin`, {organizationIds, companyAdmin})
}