import './SendNotificationCounts.scss'
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import {i18n} from "../../../Utils/i18n/i18n";
import React from "react";
import {MessageTypes} from "../../../Utils/Constants";


export const SendNotificationCounts = ({canSend, loadingSMSParameters, usersToSend: {sendToUsersWithPhone, sendToUsersWithEmail, totalUsersCount}, companySMSQuota, selectedSentToOption, messagingTypes}) => {
    let messagesToSendCount = 0
    if(messagingTypes.includes(MessageTypes.SMS)){
        messagesToSendCount += sendToUsersWithPhone
    }
    if(messagingTypes.includes(MessageTypes.EMAIL)){
        messagesToSendCount += sendToUsersWithEmail
    }
    if(messagingTypes.includes(MessageTypes.WHATSAPP)){
        messagesToSendCount += sendToUsersWithPhone
    }
    return <div className="SendNotificationCounts">
        {loadingSMSParameters ? <LoadingSpinner/> : ""}
        {canSend() && totalUsersCount && messagesToSendCount && companySMSQuota >= messagesToSendCount && !loadingSMSParameters ?
            <div className="smsStatusInformation">
                <div
                    className="statusTitle">{i18n.translate('sendSMSModal.smsStatusInformation.statusTitle')}</div>
                <div className="statusData statusUsertToNotify">
                    {i18n.translate('sendSMSModal.smsStatusInformation.statusUserToNotify')}
                    <span className="status-bold">{totalUsersCount}</span>
                    <span
                        className="status-bold">{i18n.translate('sendSMSModal.smsStatusInformation.employees')}</span>
                    <div>{i18n.translate('sendSMSModal.smsStatusInformation.theyWillReceiveItAsFollowing')}</div>
                    {messagingTypes.includes(MessageTypes.SMS) ? <div>
                        <span className="status-bold">{sendToUsersWithPhone}</span>
                        <span
                            className="status-bold">{i18n.translate('sendSMSModal.smsStatusInformation.employees')}</span>
                        <span>{i18n.translate('sendSMSModal.smsStatusInformation.bySMS')}</span>
                    </div> : ''}
                    {messagingTypes.includes(MessageTypes.EMAIL)  ? <div>
                        <span className="status-bold">{sendToUsersWithEmail}</span>
                        <span
                            className="status-bold">{i18n.translate('sendSMSModal.smsStatusInformation.employees')}</span>
                        <span>{i18n.translate('sendSMSModal.smsStatusInformation.byEmail')}</span>
                    </div> : ''}
                    {messagingTypes.includes(MessageTypes.WHATSAPP)  ? <div>
                        <span className="status-bold">{sendToUsersWithPhone}</span>
                        <span
                            className="status-bold">{i18n.translate('sendSMSModal.smsStatusInformation.employees')}</span>
                        <span>{i18n.translate('sendSMSModal.smsStatusInformation.byWhatsApp')}</span>
                    </div> : ''}

                </div>
                <div className="statusData statusCompanyQuota">
                    {i18n.translate('sendSMSModal.smsStatusInformation.statusCompanyQuota')}
                    <span className="status-bold">{companySMSQuota - messagesToSendCount}</span>
                    <span
                        className="status-bold">{i18n.translate('sendSMSModal.smsStatusInformation.SMSForUse')}</span>
                </div>
            </div> : ''}
        {!messagesToSendCount && selectedSentToOption === 'onlyNotNotified' && canSend() && !loadingSMSParameters ?
            <div className="statusError">
                {i18n.translate('sendSMSModal.error.noUsersToSendOnlyNotNotified')}
            </div> : ''}
        {!messagesToSendCount && selectedSentToOption !== 'onlyNotNotified' && canSend() && !loadingSMSParameters ?
            <div className="statusError">
                {i18n.translate('sendSMSModal.error.noUsersInCourse')}
            </div> : ''}
        {companySMSQuota < messagesToSendCount && canSend() && !loadingSMSParameters ? <div className="statusError">
            <div>
                {i18n.translate('sendSMSModal.error.notEnoughSMS')}
            </div>
            <div>
                {i18n.translate('sendSMSModal.error.youAreLeftWithQuota')}
                <span
                    className="errorBold">{companySMSQuota}</span>, {i18n.translate('sendSMSModal.error.youNeed')}
                <span
                    className="errorBold">{messagesToSendCount}</span> {i18n.translate('sendSMSModal.error.toCompleteTheProcess')}
            </div>
        </div> : ''}
    </div>
}