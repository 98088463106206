const imagesBaseUrl = `${process.env.REACT_APP_PUBLIC_FILES_URL}/images/default-course-images/example-course`
export const imageOptionsArray = [
    `${imagesBaseUrl}-1.png`,
    `${imagesBaseUrl}-2.png`,
    `${imagesBaseUrl}-3.png`,
    `${imagesBaseUrl}-4.png`,
    `${imagesBaseUrl}-5.png`,
    `${imagesBaseUrl}-6.png`,
    `${imagesBaseUrl}-7.png`,
    `${imagesBaseUrl}-8.png`,
    `${imagesBaseUrl}-9.png`,
    `${imagesBaseUrl}-10.png`,
    `${imagesBaseUrl}-11.png`,
    `${imagesBaseUrl}-12.png`,
    `${imagesBaseUrl}-13.png`,
    `${imagesBaseUrl}-14.png`,
    `${imagesBaseUrl}-15.png`,
    `${imagesBaseUrl}-16.png`,
    `${imagesBaseUrl}-17.png`,
    `${imagesBaseUrl}-18.png`,
    `${imagesBaseUrl}-19.png`,
    `${imagesBaseUrl}-20.png`,
    `${imagesBaseUrl}-21.png`,
    `${imagesBaseUrl}-22.png`,
    `${imagesBaseUrl}-23.png`,
    `${imagesBaseUrl}-24.png`,
    `${imagesBaseUrl}-25.png`,
    `${imagesBaseUrl}-26.png`,
    `${imagesBaseUrl}-27.png`,
    `${imagesBaseUrl}-28.png`,
    `${imagesBaseUrl}-29.png`,
    `${imagesBaseUrl}-30.png`,
    `${imagesBaseUrl}-31.png`,
    `${imagesBaseUrl}-32.png`,
    `${imagesBaseUrl}-33.png`,
    `${imagesBaseUrl}-34.png`,
    `${imagesBaseUrl}-35.png`,
    `${imagesBaseUrl}-36.png`,
    `${imagesBaseUrl}-37.png`,
    `${imagesBaseUrl}-38.png`,
    `${imagesBaseUrl}-39.png`,
    `${imagesBaseUrl}-40.png`,
    `${imagesBaseUrl}-41.png`,
    `${imagesBaseUrl}-42.png`,
    `${imagesBaseUrl}-43.png`,
    `${imagesBaseUrl}-44.png`,
    `${imagesBaseUrl}-45.png`,
    `${imagesBaseUrl}-46.png`,
    `${imagesBaseUrl}-47.png`,
    `${imagesBaseUrl}-48.png`,
    `${imagesBaseUrl}-49.png`,
    `${imagesBaseUrl}-50.png`,
    `${imagesBaseUrl}-51.png`,
    `${imagesBaseUrl}-52.png`,
    `${imagesBaseUrl}-53.png`,
    `${imagesBaseUrl}-54.png`,
    `${imagesBaseUrl}-55.png`,
    `${imagesBaseUrl}-56.png`,
    `${imagesBaseUrl}-57.png`,
    `${imagesBaseUrl}-58.png`,
    `${imagesBaseUrl}-59.png`,
    `${imagesBaseUrl}-60.png`,
]