import {Button} from "../../../Form/Button/Button";
import {buttonColors} from "../../../Pickers/InputTheme";
import {i18n} from "../../../../Utils/i18n/i18n";
import React from "react";
import {GridReactContext} from "../../../../Utils/DataGridWrapper/GridContext";
import EditIcon from '@mui/icons-material/Edit';
import './EditAdminUserCell.scss'
import {AddEditAdminUserModal} from "../AddEditAdminUserModal/AddEditAdminUserModal";

export const EditAdminUserCell = ({user: {id: userId, isCompanyAdmin: isViewedUserCompanyAdmin}, isCompanyAdmin}) => {
    const [editAdminUserModalOpen, setEditAdminUserModalOpen] = React.useState(false)
    const gridContext = React.useContext(GridReactContext)
    const closeCreateEditUserModal = () => {
        setEditAdminUserModalOpen(false)
        gridContext.triggerTableRefresh()
    }
    const editUser = () => {
        setEditAdminUserModalOpen(true)
    }
    return <div className="EditAdminUserCell">
        {!isViewedUserCompanyAdmin ? <Button color={buttonColors.GREY}
                 size="small"
                 icon={<EditIcon/>}
                 // text={i18n.translate('createEditUserModal.editUser')}
                 onClick={editUser}/> : i18n.translate('createEditUserModal.userIsCompanyAdmin')}
        {editAdminUserModalOpen ? <AddEditAdminUserModal editMode key={userId} userId={userId} editAdminUserModalOpen={editAdminUserModalOpen} closeEditAdminUserModal={closeCreateEditUserModal} isCompanyAdmin={isCompanyAdmin}/> : ''}
    </div>
}