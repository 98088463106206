import {i18n} from "../../../../Utils/i18n/i18n";
import {Button} from "../../../Form/Button/Button";
import {buttonColors} from "../../../Pickers/InputTheme";
import React, {useContext, useEffect, useRef, useState} from "react";
import {Modal} from "../../../Smart/Modal/Modal";
import {SelectBox} from "../../../Pickers/SelectBox/SelectBox";
import './CreateAutomationModal.scss'
import {createNotificationAutomationAPI, getCompanySMSQuotaAPI, userCountToSendSMSAPI} from "../../../../Services/api";
import {get, find} from "lodash";
import {FormInput} from "../../../Form/Input/FormInput";
import {AutomationSubTypes, MessageTypes} from "../../../../Utils/Constants";
import LoadingSpinner from "../../../LoadingSpinner/LoadingSpinner";
import {MessageTypeSelectionButtons} from "../../MessageTypeSelectionButtons/MessageTypeSelectionButtons";
import {GridReactContext} from "../../../../Utils/DataGridWrapper/GridContext";
import {useUserAdminOrgs} from "../../../Hooks/UseUserAdminOrgs";
import {getTaskOptionsForNotificationsInOrganization} from "../../../Settings/Reminders/RemindersLib";
import {SendNotificationCounts} from "../../SendNotificationCounts/SendNotificationCounts";
import {UserReactContext} from "../../../../contexts/UserContext";
import {CompanyReactContext} from "../../../../contexts/CompanyContext";
import {EntityTypes} from "../../NotificationsLib";


const getHoursOptions = () => [
    {
        value: '10:00', text: '10:00'
    },
    {
        value: '11:00', text: '11:00'
    },
    {
        value: '12:00', text: '12:00'
    },
    {
        value: '13:00', text: '13:00'
    },
    {
        value: '14:00', text: '14:00'
    },
    {
        value: '15:00', text: '15:00'
    },
    {
        value: '16:00', text: '16:00'
    },
    {
        value: '17:00', text: '17:00'
    },
    {
        value: '18:00', text: '18:00'
    },
    {
        value: '19:00', text: '19:00'
    }

]
export const CreateAutomationModal = ({automationModalOpen, closeAutomationModal, automation}) => {
    const {adminOrganizationOptions, adminOrganizations, isOrgsLoading} = useUserAdminOrgs({
        withAllOption: !automation,
        withCoursesAndSignDocs: true
    })
    const [selectedOrgOption, setSelectedOrgOption] = useState(get(automation, 'organizationIds[0]') || 'all')
    const [selectedTaskOption, setSelectedTaskOption] = useState(get(automation, 'signDocIds[0]') || '')
    const [signDocsOptions, setSignDocsOptions] = useState([])
    const [selectedHour, setSelectedHour] = useState(get(automation, 'runByEventRelativeTime.runHour') || getHoursOptions()[0].value)
    const [daysBefore, setDaysBefore] = useState(get(automation, 'runByEventRelativeTime.runDaysBefore') || 5)
    const [loading, setLoading] = useState(false)
    const [automationName, setAutomationName] = useState(get(automation, 'name', ''))
    const [automationDescription, setAutomationDescription] = useState(get(automation, 'description', ''))
    const [selectedMessagingTypes, setSelectedMessagingTypes] = useState(automation ? [get(automation, 'type')] : [MessageTypes.SMS])
    const [isEditMode] = useState(!!automation)
    const [loadingSMSParameters, setLoadingSMSParameters] = useState(false)
    const [usersToSend, setUsersToSend] = useState({})
    const [companySMSQuota, setCompanySMSQuota] = useState(null)
    const oneTimeIgnoreCourseReset = useRef(true)

    const gridContext = useContext(GridReactContext);
    const userContext = useContext(UserReactContext);
    const companyContext = useContext(CompanyReactContext);
    const handleDaysBeforeInputChange = (e) => {
        //limit value to be between 1 and 50
        if (e.target.value < 1) {
            e.target.value = 1
        }
        if (e.target.value > 50) {
            e.target.value = 50
        }
        //remove non digit notes
        e.target.value = e.target.value.replace(/\D/g, '')
        setDaysBefore(e.target.value)
    }

    const onSelectedMessagingTypeChange = (messagingType) => {
        //if it is the only one selected do not remove it
        if(isEditMode){
            setSelectedMessagingTypes([messagingType])
            return
        }
        if (selectedMessagingTypes.includes(messagingType) && selectedMessagingTypes.length > 1) {
            setSelectedMessagingTypes(selectedMessagingTypes.filter((type) => type !== messagingType))
        } else {
            let messagingTypes = [...selectedMessagingTypes, messagingType]
            //uniq array
            messagingTypes = messagingTypes.filter((value, index, self) => self.indexOf(value) === index)
            setSelectedMessagingTypes(messagingTypes)
        }
    }
    const onSelectedHour = (e) => {
        setSelectedHour(e.target.value)
    }
    const loadSMSParameters = async () => {
        if (!selectedTaskOption || !selectedOrgOption) {
            return
        }
        //is selected option a course or a sign doc
        const entityType = EntityTypes.SIGN_DOC
        setLoadingSMSParameters(true)
        const {sendToUsersWithPhone, sendToUsersWithEmail, totalUsersCount} = await userCountToSendSMSAPI({entityId: selectedTaskOption, entityType, organizationId: selectedOrgOption , skipAlreadyNotifiedUsers: false})
        const {remainingQuota} = await getCompanySMSQuotaAPI({companyId: userContext.getUserCompanyId()})
        setCompanySMSQuota(remainingQuota)
        setUsersToSend({sendToUsersWithPhone, sendToUsersWithEmail, totalUsersCount})
        setLoadingSMSParameters(false)
    }
    useEffect(() => {
        loadSMSParameters()
    }, [selectedTaskOption]);


    const onSelectedCourseOptionChange = (e) => {
        setSelectedTaskOption(e.target.value)
    }
    const onSelectedOrgOptionChange = (e) => {
        setSelectedOrgOption(e.target.value)
    }
    const onAutomationNameChange = (e) => {
        setAutomationName(e.target.value)
    }
    const onAutomationDescriptionChange = (e) => {
        setAutomationDescription(e.target.value)
    }
    const setsignDocsOptions = () => {
        let {signDocOptionsTmp} = getTaskOptionsForNotificationsInOrganization({selectedOrgOption, adminOrganizations, companyContext});
        setSignDocsOptions(signDocOptionsTmp)
    }

    useEffect(() => {
        setsignDocsOptions()
        if (isEditMode && oneTimeIgnoreCourseReset.current) {
            return
        }
        setSelectedTaskOption("")
        oneTimeIgnoreCourseReset.current = false

    }, [selectedOrgOption, adminOrganizationOptions, adminOrganizations])


    const getError = (ignoreFactorsWhichDoNotEffectCount) => {
        if (!selectedOrgOption) {
            return i18n.translate('automations.createAutomationModal.organizationOptions.error')
        }
        if (!selectedTaskOption) {
            return i18n.translate('automations.createAutomationModal.taskOptions.error')
        }
        if(ignoreFactorsWhichDoNotEffectCount){
            return false
        }
        else{
            if (!selectedHour) {
                return i18n.translate('automations.createAutomationModal.selectHourError')
            }
            if (!isDaysBeforeValid()) {
                return i18n.translate('automations.createAutomationModal.daysBeforeError')
            }
            if (!automationName) {
                return i18n.translate('automations.createAutomationModal.nameError')
            }
        }

        return false
    }
    const canSend = () => {
        return !getError(true)
    }
    const isDaysBeforeValid = () => {
        const daysBeforeInt = parseInt(daysBefore)
        return daysBeforeInt >= 1 && daysBeforeInt <= 50
    }

    const createNotificationAutomation = () => {
        setLoading(true)
        const entityType = EntityTypes.SIGN_DOC
        let subType = AutomationSubTypes.DOC_TO_SIGN_REMINDER;

        if (isEditMode) {
            //edit automation
            createNotificationAutomationAPI({
                runHour: selectedHour,
                runDaysBefore: daysBefore,
                types: selectedMessagingTypes,
                subType: subType,
                entityId: selectedTaskOption,
                entityType,
                organizationId: selectedOrgOption,
                name: automationName,
                description: automationDescription,
                automationId: automation.id
            })
                .then(() => {
                    closeAutomationModal()
                })
                .finally(() => {
                    setLoading(false)
                    gridContext.triggerTableRefresh()
                })
            return
        }
        createNotificationAutomationAPI({
            runHour: selectedHour,
            runDaysBefore: daysBefore,
            types: selectedMessagingTypes,
            subType: subType,
            entityId: selectedTaskOption,
            entityType,
            organizationId: selectedOrgOption,
            name: automationName,
            description: automationDescription
        })
            .then(() => {
                closeAutomationModal()
            })
            .finally(() => {
                setLoading(false)
                gridContext.triggerTableRefresh()
            })
    }

    return <Modal
        open={automationModalOpen}
        onClose={closeAutomationModal}>
        <div className="createAutomationModal modalWrapper">
            <div className="modalTitle">
                <div>{i18n.translate('sendSMSModal.title')}</div>
            </div>
            <div className="modalContent">
                {loading ? <LoadingSpinner/> : ""}
                <div className="selectBoxes">
                    <div className="nameAndDescriptionInputs">
                        <div className="nameInput">
                            <FormInput
                                required
                                dontShowErrorMessage
                                id="name"
                                label={i18n.translate('automations.createAutomationModal.nameLabel')}
                                className="login-text-field first"
                                value={automationName}
                                onChange={onAutomationNameChange}
                            />
                        </div>
                        <div className="descriptionInput">
                            <FormInput
                                dontShowErrorMessage
                                id="description"
                                label={i18n.translate('automations.createAutomationModal.descriptionLabel')}
                                className="login-text-field first"
                                value={automationDescription}
                                onChange={onAutomationDescriptionChange}
                            />
                        </div>
                    </div>
                    <div className="selectCourseAndOrg">
                        <div className="selectOrg">
                            {adminOrganizationOptions ?
                                <SelectBox
                                    key="selectOrg"
                                    options={adminOrganizationOptions} value={selectedOrgOption}
                                    label={i18n.translate('automations.createAutomationModal.organizationOptions.label')}
                                    onChange={onSelectedOrgOptionChange}/> : ""}
                        </div>
                        <div className="selectCourse">
                            {signDocsOptions.length ? <SelectBox
                                key="selectCourse"
                                options={signDocsOptions} value={selectedTaskOption}
                                label={i18n.translate('automations.createAutomationModal.taskOptions.label')}
                                onChange={onSelectedCourseOptionChange}/> : ""}
                        </div>
                    </div>
                    <div className="selectHourAndDaysBefore">
                        <div className="selectHour">
                            <SelectBox
                                key="selectHour"
                                options={getHoursOptions()} value={selectedHour}
                                label={i18n.translate('automations.createAutomationModal.selectHourLabel')}
                                onChange={onSelectedHour}/>
                        </div>
                        <div className="daysBeforeInput">
                            <FormInput
                                dontShowErrorMessage
                                id="daysBefore"
                                label={i18n.translate('automations.createAutomationModal.howManyDaysBefore')}
                                className="login-text-field first"
                                value={daysBefore}
                                onChange={handleDaysBeforeInputChange}
                                type="number"
                                inputProps={{
                                    maxLength: 2,
                                    inputMode: 'numeric',
                                    pattern: '[0-9]*'
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="messagingTypeWrapper">
                    <MessageTypeSelectionButtons onSelectedMessageTypeChange={onSelectedMessagingTypeChange}
                                                 selectedMessagingTypes={selectedMessagingTypes}/>
                </div>
                <SendNotificationCounts canSend={canSend} loadingSMSParameters={loadingSMSParameters} usersToSend={usersToSend}
                                        messagingTypes={selectedMessagingTypes}
                                        companySMSQuota={companySMSQuota} selectedSentToOption={""}/>
            </div>
            <div className="modalFooter">
                <Button
                    color={buttonColors.GREY}
                    onClick={closeAutomationModal}
                    text={i18n.translate('general.cancel')}
                />
                <Button
                    color={buttonColors.YELLOW}
                    onClick={createNotificationAutomation}
                    disabled={getError()}
                    text={isEditMode ? i18n.translate('general.saveChanges') : i18n.translate('automations.createAutomationModal.create')}
                />

            </div>
        </div>
    </Modal>
}