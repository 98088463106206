import './UserRowSignDocsMenu.scss'
import {
    getSignDocDateText,
    signDocToClassProgressValueConverter,
    signDocToTextProgressValueConverter
} from "../../../Utils/BusinessLib";
import {buttonColors} from "../../Pickers/InputTheme";
import {i18n} from "../../../Utils/i18n/i18n";
import {Button} from "../../Form/Button/Button";
import React, {useContext, useState} from "react";
import {
    SelectFinishDateForUserCourseModal
} from "../../SelectFinishDateForUserCourseModal/SelectFinishDateForUserCourseModal";
import {addUserToSignDocAPI, markSignDocUserAsCompletedAPI, removeUserSignDocAPI} from "../../../Services/api";
import {GridReactContext} from "../../../Utils/DataGridWrapper/GridContext";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";

export const UserRowSignDocsMenu = ({
                                        signed,
                                        viewed,
                                        signDoc,
                                        userId,
                                        userName,
                                        finishByDate,
                                        organizationId,
                                        completedOnDate,
                                        isSignDocValid,
                                        progressPercent,
                                        completedByUserName,
                                        type
                                    }) => {
    const gridContext = useContext(GridReactContext)
    const {progress, isCompleted} = signDocToTextProgressValueConverter({signed, viewed, isSignDocValid, progressPercent, type})
    const signDocDateText = getSignDocDateText({finishByDate, completedOnDate, signed, viewed, type, progressPercent, completedByUserName})
    const progressClass = signDocToClassProgressValueConverter({signed, viewed, progressPercent, type})
    const [isHovered, setIsHovered] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isDateModalOpen, setIsDateModalOpen] = useState(false)
    const onMenuMouseEnter = () => {
        setIsHovered(true)
    }
    const onMenuMouseLeave = () => {
        setIsHovered(false)
    }
    const onClickAddCourse = () => {
        setIsDateModalOpen(true)
    }
    const onAddUserToSignDocSave = async ({signDocId, userId, finishDate}) => {
        setIsLoading(true)
        await addUserToSignDocAPI({signDocId, userId, finishDate, organizationId})
        setIsDateModalOpen(false)
        gridContext.triggerTableRefresh()
        setIsLoading(false)
    }
    const removeUserFromSignDoc = async () => {
        setIsLoading(true)
        await removeUserSignDocAPI({signDocId: signDoc.id, userId, organizationId})
        gridContext.triggerTableRefresh()
        setIsLoading(false)
    }
    const markCompleted = async () => {
        setIsLoading(true)
        await markSignDocUserAsCompletedAPI({signDocId: signDoc.id, userId, organizationId})
        gridContext.triggerTableRefresh()
        setIsLoading(false)
    }
    const closeSelectFinishDateModal = () => {
        setIsDateModalOpen(false)
    }

    const getButtonToShow = () => {
        if (!isSignDocValid) {
            return <Button color={buttonColors.GOLD}
                           text={i18n.translate('UsersPage.userRowSignDocMenu.buttons.addToSignDoc')}
                           onClick={onClickAddCourse}/>
        } else {
            return <div className={`cellButtonsWrapper ${isCompleted ? 'singleVal' : ''}`}>
                <Button color={buttonColors.LIGHT_GREY}
                        text={i18n.translate('UsersPage.userRowSignDocMenu.buttons.removeFromSignDoc')}
                        onClick={removeUserFromSignDoc}/>
                {!isCompleted ? <Button color={buttonColors.GOLD}
                         text={i18n.translate('UsersPage.userRowSignDocMenu.buttons.markCompleted')}
                         onClick={markCompleted}/> : ''}
            </div>
        }
    }
    return <div className="UserRowSignDocsMenu" onMouseEnter={onMenuMouseEnter} onMouseLeave={onMenuMouseLeave}>
        {isLoading ? <LoadingSpinner/> : '' }
        <div className={`cellCourseProgress  ${isHovered ? 'hidden' : 'visible'}`}>
            <div className={`progressText ${progressClass}`}> {progress}</div>
            {signDocDateText ? <div className="progressDate">{signDocDateText}</div> : ''}

        </div>
        <div className={`userRowButtons ${isHovered ? 'visible' : 'hidden'}`}>
            {getButtonToShow()}
        </div>
        {isDateModalOpen ? <SelectFinishDateForUserCourseModal
            key={userId + signDoc.id}
            signDoc={signDoc}
            userName={userName}
            userId={userId}
            closeSelectFinishDateModal={closeSelectFinishDateModal}
            isModalOpen={isDateModalOpen}
            currentFinishDate={finishByDate}
            onSave={onAddUserToSignDocSave}/> : ''}
    </div>
}