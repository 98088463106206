import {Modal} from "../../../Smart/Modal/Modal";
import {i18n} from "../../../../Utils/i18n/i18n";
import './AddEditAdminUserModal.scss';
import React, {useEffect} from "react";
import {Button} from "../../../Form/Button/Button";
import {buttonColors} from "../../../Pickers/InputTheme";
import {
    getAdminOrganizationsForUserAPI,
    getMyAdminOrganizationsUsersAPI,
    getUserAPI,
    saveUserAsAdminOfOrganizationsAPI
} from "../../../../Services/api";
import LoadingSpinner from "../../../LoadingSpinner/LoadingSpinner";
import {useUserAdminOrgs} from "../../../Hooks/UseUserAdminOrgs";
import {MultiSelectAutocomplete} from "../../../Form/AutoComplete/AutoComplete";
import {filter} from "lodash";
import {FormControlLabel, Switch} from "@mui/material";

export const AddEditAdminUserModal = ({
                                          editAdminUserModalOpen,
                                          closeEditAdminUserModal,
                                          userId,
                                          editMode,
                                          isCompanyAdmin
                                      }) => {
    const {adminOrganizationOptions, adminOrganizations, isOrgsLoading} = useUserAdminOrgs({})
    const [isLoading, setIsLoading] = React.useState(true)
    const [selectedOrgs, setSelectedOrgs] = React.useState([])
    const [selectedUser, setSelectedUser] = React.useState()
    const [isLoadingUserData, setIsLoadingUserData] = React.useState(editMode ? true : false)
    const [companyAdmin, setCompanyAdmin] = React.useState(false)
    const init = async () => {
        if (isOrgsLoading) {
            return
        }
        setIsLoading(true)
        //edit mode
        if (userId) {
            const user = await getUserAPI({userId})
            setSelectedUser({value: user.id, text: `${user.firstName} ${user.lastName}`})
            setCompanyAdmin(user.isCompanyAdmin)
        }
        setTimeout(() => {
            setIsLoading(false)
        }, 0)

    }
    useEffect(() => {
        init()
    }, [isOrgsLoading]);

    const saveAdminChanges = async () => {
        setIsLoading(true)
        const userId = selectedUser?.value
        const organizationIds = companyAdmin ? [] : selectedOrgs.map(org => org.value)
        await saveUserAsAdminOfOrganizationsAPI({userId, organizationIds, companyAdmin})
        setIsLoading(false)
        closeEditAdminUserModal()
    }

    const getFilteredOrgs = ({query}) => {
        if (selectedUser && (!query || query.length === 0 || query === "")) {
            return adminOrganizationOptions
        }
        return adminOrganizationOptions.filter(org => org.text.toLowerCase().includes(query.toLowerCase()))
    }
    const onOrgsSelectionChange = (selectedOrgs) => {
        setSelectedOrgs(selectedOrgs)
    }
    const getFilteredUsers = async ({query}) => {
        if (editMode) {
            return [{value: selectedUser.value, text: selectedUser.text}]
        }
        const filterModel = {
            filterValues: {
                search: query
            }
        }
        const users = await getMyAdminOrganizationsUsersAPI({filterModel})
        return users.map(user => ({value: user.id, text: `${user.firstName} ${user.lastName}`}))
    }

    const onCompanyAdminChange = (e) => {
        setCompanyAdmin(e.target.checked)
    }

    const getSelectedUserAdminOrgs = async () => {
        setIsLoadingUserData(true)
        let tmpUserId
        if (editMode) {
            tmpUserId = userId
        } else {
            tmpUserId = selectedUser?.value
        }
        if (tmpUserId) {
            const adminOrganizationIds = await getAdminOrganizationsForUserAPI({userId: tmpUserId})
            const selectedOrgsOptions = filter(adminOrganizationOptions, org => adminOrganizationIds.includes(org.value))
            setSelectedOrgs(selectedOrgsOptions)
        } else {
            setSelectedOrgs([])
        }
        setTimeout(() => {
            setIsLoadingUserData(false)
        }, 0)
    }
    const onUserSelectionChange = async (selectedUser) => {
        setSelectedUser(selectedUser)
    }

    useEffect(() => {
        getSelectedUserAdminOrgs()
    }, [selectedUser]);


    return <Modal
        open={editAdminUserModalOpen}
        onClose={closeEditAdminUserModal}>
        <div className="AddEditAdminUserModal modalWrapper">
            <div className="modalTitle">
                <span>{i18n.translate(`editAdminUserModal.${editMode ? 'titleEdit' : 'title'}`)}: {selectedUser?.text}</span>
            </div>
            <div className="modalContent">
                {(isLoading || isLoadingUserData) && <LoadingSpinner/>}
                <div className="selectUser">
                    {!isLoading ? <MultiSelectAutocomplete
                        disabled={editMode}
                        label={i18n.translate('editAdminUserModal.searchUser')}
                        isSingleSelect
                        initialSelectedValues={selectedUser}
                        fetchOptions={getFilteredUsers}
                        onSelectionChange={onUserSelectionChange}/> : ''}
                </div>

                <div className="adminSwitchWrapper">
                    <FormControlLabel control={<Switch
                        disabled={!isCompanyAdmin}
                        checked={companyAdmin}
                        onChange={onCompanyAdminChange}
                    />} label={i18n.translate('editAdminUserModal.isCompanyAdmin')}/>
                </div>


                <div className="managingOrganizations">
                    {isLoadingUserData || companyAdmin ? "" : <MultiSelectAutocomplete
                        selectAllText={i18n.translate('editAdminUserModal.selectAllOrgs')}
                        enableSelectAll
                        key={selectedUser?.value}
                        label={i18n.translate('editAdminUserModal.selectOrganizationalUnits')}
                        fetchOptions={getFilteredOrgs}
                        disabled={!selectedUser}
                        initialSelectedValues={selectedOrgs}
                        onSelectionChange={onOrgsSelectionChange}/>}
                    {companyAdmin ? `*${i18n.translate('editAdminUserModal.companyAdminWillBeAdminOfAllOrgs')}` : ""}
                </div>
            </div>
            <div className="modalFooter">
                <Button
                    color={buttonColors.GREY}
                    onClick={closeEditAdminUserModal}
                    text={i18n.translate('general.close')}
                />
                <Button
                    color={buttonColors.YELLOW}
                    onClick={saveAdminChanges}
                    disabled={!selectedUser}
                    text={i18n.translate('general.saveChanges')}
                />
            </div>
        </div>
    </Modal>;
}
