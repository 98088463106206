import {i18n} from "../../../Utils/i18n/i18n";
import {get, map} from 'lodash'
import {useState} from "react";
import {DataGridWrapper} from "../../../Utils/DataGridWrapper/DataGridWrapper";
import {getCompanyFilesAPI} from "../../../Services/api";
import {TableDownloadLink} from "../../../Utils/TableDownloadLink/TableDownloadLink";
import {IsReadyForDownloadCell} from "../IsReadyForDownloadCell/IsReadyForDownloadCell";
import {getFormattedGMTPlus2DateAndTime} from "../../../Utils/Date/Date";

const getColumns = async ({}) => {

    return [
        //add columns for the table: fileName, presentationsName, companyName, organizationName date, status
        {
            field: 'isReady',
            headerName: i18n.translate("FilesManagementPage.fileManagementTable.columns.isReady"),
            minWidth: 120,
            align: 'right',
            sortable: false,
            renderCell: (params) => <IsReadyForDownloadCell value={params.value}/>,
        },
        {
            field: 'fileName',
            headerName: i18n.translate("FilesManagementPage.fileManagementTable.columns.fileName"),
            minWidth: 100,
            align: 'right',
            sortable: false,
            renderCell: (params) => TableDownloadLink({fileId: params.row.id}),
        },
        {
            field: 'presentationName',
            headerName: i18n.translate("FilesManagementPage.fileManagementTable.columns.presentationName"),
            minWidth: 220,
            align: 'right',
            sortable: false,
            renderCell: (params) => <div style={{width: '100%'}} title={params.value}>{params.value}</div>,
        },
        {
            field: 'companyName',
            headerName: i18n.translate("FilesManagementPage.fileManagementTable.columns.companyName"),
            minWidth: 150,
            align: 'right',
            sortable: false,
            renderCell: (params) => <div style={{width: '100%'}} title={params.value}>{params.value}</div>,
        },
        {
            field: 'organizationNames',
            headerName: i18n.translate("FilesManagementPage.fileManagementTable.columns.organizationNames"),
            minWidth: 250,
            align: 'right',
            sortable: false,
            renderCell: (params) => <div style={{width: '100%'}} title={params.value}>{params.value}</div>,
        },
        {
            field: 'date',
            headerName: i18n.translate("FilesManagementPage.fileManagementTable.columns.date"),
            minWidth: 150,
            align: 'right',
            sortable: false,
            renderCell: (params) => <div style={{width: '100%'}} title={params.value}>{params.value}</div>,
        },

        {
            field: 'createdByUser',
            headerName: i18n.translate("FilesManagementPage.fileManagementTable.columns.createdByUser"),
            minWidth: 100,
            align: 'right',
            sortable: false,
            renderCell: (params) => <div style={{width: '100%'}} title={params.value}>{params.value}</div>,
        },
    ];
}

const tableFilters = {
    showFilters: {
        search: true,
    },
    filterValues: {},
    filterData: {}
}

const convertData = (companyFiles) => {
    return map(companyFiles, (file) => {
        return {
            id: file.id,
            fileName: file.fileName,
            presentationName: file.presentationName,
            companyName: get(file, 'company.companyName'),
            organizationNames: map(file.organizations, 'orgName').join(', '),
            date: getFormattedGMTPlus2DateAndTime(file.createdAt),
            isReady: file.isReady,
            createdByUser: get(file, 'createdByUser.firstName') + ' ' + get(file, 'createdByUser.lastName')
        }
    })
}

const paginationModel = {
    page: 0,
    pageSize: 20,
}

export const GeneratedReportsTable = ({organizationsOptions}) => {
    const [localTableFilters, setLocalTableFilters] = useState({
        ...tableFilters,
        filterData: {
            ...tableFilters.filterData,
            // organizations: organizationsOptions
        },
        filterValues: {
            ...tableFilters.filterValues,
            // organizationId: get(organizationsOptions, '[0].value')
        }
    })

    const apiToGetData = async ({paginationModel, filterModel, sortModel}) => {
        return getCompanyFilesAPI({paginationModel, filterModel, sortModel})
    }
    return <DataGridWrapper
            filterModel={localTableFilters}
            disableColumnFilter
            paginationModel={paginationModel}
            getColumns={getColumns}
            apiToGetData={apiToGetData}
            dataConverter={convertData}
        />
}